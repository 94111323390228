<template>
  <div>
    <div style="margin: 15px">
      <h4>{{ $t("bulletin.performancesMatiere") }}</h4>
    </div>

    <!--begin::Chart-->
    <apexchart class="statistics-widget-3-chart card-rounded-bottom" :options="options" :series="series" :height="250"
      type="pie"></apexchart>
    <!--end::Chart-->
  </div>
</template>

<script>
import apiClient from "@/services/axios"

export default {
  props: ["subject", "classroom", "certifParams"],
  data() {
    return {
      options: {},
      series: [0, 0, 0, 0],
      labelColor: "#3F4254",
      colors: ["#0095E8", "#67c23a", "#D9214E"],
      lightColors: ["#FFF5F8", "#e8fff3", "#F1FAFF"],
    }
  },

  watch: {
    subject: function (value) {
      this.updateChart()
    },

    classroom: function (value) {
      this.updateChart()
    },
  },

  created() {
    this.updateChart()
  },

  methods: {
    getCertificationNameByMoyenne(moyenne) {
      let min = 20
      let c
      for (const cert of this.certifParams) {
        if (Number(cert.min) < min) min = Number(cert.min)
        if (Number(moyenne) >= Number(cert.min) && Number(moyenne) <= Number(cert.max)) c = cert
      }
      if (c) {
        return c
      }
      return { max: min, min: 0 }
    },
    async updateChart() {
      const marksSerie = []
      const namesSerie = []
      const nbCerts = {}

      await apiClient
        .post("/mark/filter", {
          query: {
            subject: this.subject,
            classroom: this.classroom,
          },
        })
        .then(({ data }) => {
          data.forEach((mark) => {
            const cert = this.getCertificationNameByMoyenne(mark.mark)
            const name = `${cert.min} - ${cert.max}`
            if (nbCerts[name]) { nbCerts[name]++ } else { nbCerts[name] = 1 }
          })

          for (const name in nbCerts) {
            namesSerie.push(name)
            marksSerie.push(nbCerts[name])
          }
        })

      this.updateOptions(namesSerie)
      this.updateSeries(marksSerie)
    },

    updateSeries(marksSerie) {
      this.series = marksSerie
    },
    updateOptions(namesSerie) {
      this.options = {
        chart: {
          height: 250,
          type: "pie",
        },
        labels: namesSerie,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      }
    },
  },
}
</script>

<template>
  <div>
    <div style="margin: 15px">
      <h4>{{ $t("bulletin.certByMoyenne") }}</h4>
    </div>
    <!--begin::Chart-->
    <apexchart class="statistics-widget-3-chart card-rounded-bottom" :options="options" :series="series" :height="300"
      type="bar"></apexchart>
    <!--end::Chart-->
  </div>
</template>

<script>

export default {
  props: ['data', 'certifParams'],
  data() {
    return {
      options: {},
      series: [],
    }
  },

  watch: {
    data: function (value) {
      this.updateChart()
    },

    studentName: function (value) {
      this.updateChart()
    },

    subject: function () {
      this.updateChart()
    },
  },

  created() {
    this.updateChart()
  },

  methods: {
    getCertificationNameByMoyenne(moyenne) {
      for (const cert of this.certifParams) {
        if (Number(moyenne) >= Number(cert.min) && Number(moyenne) <= Number(cert.max)) return cert.name
      }
      return 'بدون شهادة'
    },
    updateChart() {
      const nbCerts = {}
      const serie = []
      const names = []
      this.data.students.filter((s) => s.moyenne !== '--').map((s) => {
        const name = this.getCertificationNameByMoyenne(s.moyenne)
        if (nbCerts[name]) { nbCerts[name]++ } else { nbCerts[name] = 1 }
      })

      for (const name in nbCerts) {
        names.push(name)
        serie.push(nbCerts[name])
      }

      this.updateOptions(names)
      this.updateSeries(serie)
    },

    updateSeries(serie) {
      this.series = [{
        data: serie,
        name: [],
      }]
    },
    updateOptions(types) {
      this.options = {
        chart: {
          height: 300,
          type: 'bar',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          },
        },
        stroke: {
          curve: 'smooth',
          show: true,
          width: 2,
        },
        xaxis: {
          type: 'string',
          categories: types,
          labels: {
            show: false,
            style: {
              fontSize: '12px',
            },
          },
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val
              },
            },
          },
        ],
        tooltip: {
          style: {
            fontSize: '12px',
          },
          y: {},
        },
        markers: {
          strokeWidth: 1,
        },
      }
    },
  },
}
</script>
